/**
 * Created by Sergey Panpurin on 5/19/21.
 */

// @ts-check

(function btLinkDataServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btLinkDataService:';

  /**
   * This service implements ???.
   *
   * @ngdoc service
   * @name ecapp.btLinkDataService
   * @param {angular.IQService} $q - promise interface
   * @param {angular.IIntervalService} $interval
   * @param {angular.IFilterService} $filter
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ecapp.IGeneralLoopbackService} lbLdsApi
   * @return {ecapp.ILinkDataService}
   */

  angular.module('ecapp').factory('btLinkDataService', service);

  service.$inject = ['$q', '$interval', '$filter', '$rootScope', 'LdsApiWrapper'];

  /**
   *
   * @param {angular.IQService} $q - ?
   * @param {angular.IIntervalService} $interval - ?
   * @param {angular.IFilterService} $filter - ?
   * @param {ecapp.ICustomRootScope} $rootScope - ?
   * @param {*} lbLdsApi - ?
   * @return {ecapp.ILinkDataService} - ?
   */
  function service($q, $interval, $filter, $rootScope, lbLdsApi) {
    if (gDebug) console.log(gPrefix, 'running...');

    // var gSubscriptionId = 1;

    var gTransactionStorage = {};

    var gLdsGrades = [
      {
        id: 1,
        name: 'LLS',
        type: 'Physical',
        desc: 'Light Louisiana Sweet',
      },
      {
        id: 2,
        name: 'MARS',
        type: 'Physical',
        desc: 'Mars Blend',
      },
      {
        id: 3,
        name: 'MIDSWT',
        type: 'Physical',
        desc: 'WTI Midland Texas',
      },
      {
        id: 4,
        name: 'WTS',
        type: 'Physical',
        desc: 'West Texas Sour',
      },
      {
        id: 5,
        name: 'MEH',
        type: 'Physical',
        desc: 'WTI Houston Texas',
      },
      // {
      //   'id': 6,
      //   'name': 'BK / BTD',
      //   'type': 'Financial',
      //   'desc': 'WTI vs Brent 1st line (calendar month)'
      // },
      {
        id: 7,
        name: 'E5 / ARL',
        type: 'Financial',
        desc: 'LLS vs WTI (trade month)',
      },
      {
        id: 8,
        name: 'YV / ARW',
        type: 'Financial',
        desc: 'Mars vs WTI (trade month)',
      },
      {
        id: 9,
        name: 'WTT / MSV',
        type: 'Financial',
        desc: 'Midland vs WTI (trade month)',
      },
      {
        id: 10,
        name: 'HTT/ACM',
        type: 'Financial',
        desc: 'WTI @ Magellan East Houston vs WTI (trade month)',
      },
      {
        id: 11,
        name: 'FH / AVT',
        type: 'Financial',
        desc: 'WTS vs WTI (trade month)',
      },
      {
        id: 12,
        name: 'HLS',
        type: 'Physical',
        desc: 'Heavy Louisiana Sweet',
      },
      {
        id: 13,
        name: 'LOOP SOUR',
        type: 'Physical',
        desc: '...',
      },
      {
        id: 14,
        name: 'POSEIDON',
        type: 'Physical',
        desc: 'Poseidon',
      },
      {
        id: 15,
        name: 'BONITO',
        type: 'Physical',
        desc: 'Bonito',
      },
      {
        id: 16,
        name: 'T-HORSE',
        type: 'Physical',
        desc: 'Thunderhorse',
      },
      {
        id: 17,
        name: 'SGC',
        type: 'Physical',
        desc: 'Southern Green Canyon',
      },
      // {
      //   'id': 18,
      //   'name': 'WJ',
      //   'type': null,
      //   'desc': '...'
      // },
      // {
      //   'id': 19,
      //   'name': 'YX',
      //   'type': null,
      //   'desc': '...'
      // }, {
      //   'id': 20,
      //   'name': 'FF',
      //   'type': null,
      //   'desc': '...'
      // },
      {
        id: 21,
        name: 'WJ / ARK',
        type: 'Financial',
        desc: 'LLS vs WTI 1st line (calendar month)',
      },
      {
        id: 22,
        name: 'YX / ARO',
        type: 'Financial',
        desc: 'Mars vs WTI 1st line (calendar month)',
      },
      {
        id: 23,
        name: 'FF / MLT',
        type: 'Financial',
        desc: 'Midland vs WTI 1st line (calendar month)',
      },
      {
        id: 24,
        name: 'WCS CUSHING',
        type: 'Physical',
        desc: 'Western Canadian Select',
      },
      // {
      //   id: 25,
      //   name: 'WCS USGC',
      //   type: 'Physical',
      //   desc: 'Western Canadian Select',
      // },
      {
        id: 26,
        name: 'CMA',
        type: 'Physical',
        desc: 'Domestic Sweet',
      },
      {
        id: 27,
        name: 'CLK CUSHING',
        type: 'Physical',
        desc: 'Cold Lake',
      },
      {
        id: 28,
        name: 'AWB CUSHING',
        type: 'Physical',
        desc: 'Access Western Blend',
      },
      {
        id: 29,
        name: 'WCS US GULF',
        type: 'Physical',
        desc: 'Access Western Blend',
      },
      {
        id: 30,
        name: 'CLK US GULF',
        type: 'Physical',
        desc: 'Cold Lake',
      },
      {
        id: 31,
        name: 'AWB US GULF',
        type: 'Physical',
        desc: 'Access Western Blend',
      },
      {
        id: 32,
        name: 'CLK @ PATOKA',
        type: 'Physical',
        desc: 'Cold Lake',
      },
      {
        id: 33,
        name: 'BLS CUSHING',
        type: 'Physical',
        desc: 'Bakken Light Sweet',
      },
      {
        id: 34,
        name: 'NIO CUSHING',
        type: 'Physical',
        desc: 'Niobrara',
      },
      {
        id: 35,
        name: 'WHITECLIFFS',
        type: 'Physical',
        desc: 'Whitecliffs',
      },
      {
        id: 36,
        name: 'GRAND MESA LT',
        type: 'Physical',
        desc: 'Grand Mesa Light',
      },
      {
        id: 37,
        name: 'BAKKEN US GULF',
        type: 'Physical',
        desc: 'Bakken Light Sweet',
      },
      // {
      //   'id': 38,
      //   'name': 'BZ/B',
      //   'type': 'Financial',
      //   'desc': '...'
      // },
      {
        id: 39,
        name: 'WTL',
        type: 'Physical',
        desc: '...',
      },
      {
        id: 40,
        name: 'MTD',
        type: 'Financial',
        desc: '...',
      },
      {
        id: 41,
        name: 'CL',
        type: 'Financial',
        desc: '...',
      },
      {
        id: 42,
        name: 'BZ',
        type: 'Financial',
        desc: '...',
      },
      {
        id: 43,
        name: 'SHL Light',
        type: 'Physical',
        desc: '...',
      },
      {
        id: 44,
        name: 'SHL Crude',
        type: 'Physical',
        desc: '...',
      },
      {
        id: 45,
        name: 'CDB Cushing',
        type: 'Physical',
        desc: '...',
      },
      {
        id: 46,
        name: 'CDB US Gulf',
        type: 'Physical',
        desc: '...',
      },
      {
        id: 47,
        name: 'CSH',
        type: 'Financial',
        desc: '...',
      },
      {
        id: 48,
        name: 'ARV',
        type: 'Financial',
        desc: '...',
      },
      {
        id: 49,
        name: 'HTT vs WTT',
        type: 'Financial',
        desc: '...',
      },
      {
        id: 50,
        name: 'HTT vs YV',
        type: 'Financial',
        desc: '...',
      },
      {
        id: 51,
        name: 'HTT + BK',
        type: 'Financial',
        desc: '...',
      },
      {
        id: 52,
        name: 'E5 vs HTT',
        type: 'Financial',
        desc: '...',
      },
      {
        id: 53,
        name: 'E5 vs YV',
        type: 'Financial',
        desc: '...',
      },
      {
        id: 54,
        name: 'E5 vs WTT',
        type: 'Financial',
        desc: '...',
      },
      {
        id: 55,
        name: 'FH vs WTT',
        type: 'Financial',
        desc: '...',
      },
      {
        id: 56,
        name: 'YV + BK',
        type: 'Financial',
        desc: '...',
      },
      {
        id: 57,
        name: 'CSH vs ARV',
        type: 'Financial',
        desc: '...',
      },
      {
        id: 58,
        name: 'WTI EX BASIN',
        type: 'Financial',
        desc: '...',
      },
      {
        id: 59,
        name: 'MARKETLINK LIGHT',
        type: 'Financial',
        desc: '...',
      },
      {
        id: 60,
        name: 'SEAWAY LIGHT',
        type: 'Financial',
        desc: '...',
      },
      {
        id: 61,
        name: 'CASH ROLL',
        type: 'Financial',
        desc: '...',
      },
      {
        id: 62,
        name: 'P-Plus',
        type: 'Financial',
        desc: '...',
      },
      {
        id: 63,
        name: 'WTI CORPUS',
        type: null,
        desc: '...',
      },
      {
        id: 64,
        name: 'WTI NEDER',
        type: null,
        desc: '...',
      },
      {
        id: 65,
        name: 'WTI ECHO',
        type: null,
        desc: '...',
      },
      {
        id: 66,
        name: 'SHI',
        type: null,
        desc: '...',
      },
      {
        id: 67,
        name: 'Bakken Patoka',
        type: null,
        desc: '...',
      },
      {
        id: 68,
        name: 'Lt Sweet GNSY',
        type: null,
        desc: '...',
      },
      {
        id: 69,
        name: 'UHC Clearbrook',
        type: null,
        desc: '...',
      },
      {
        id: 70,
        name: 'SEAWAY HEAVY',
        type: null,
        desc: '...',
      },
    ];

    $interval(function () {
      if (gDebug) console.log('>>>>>>', new Date());
      Object.keys(gTransactionStorage).forEach(function (key) {
        var storage = /** @type {ecapp.ITransactionStorage}*/ gTransactionStorage[key];
        if (storage.subscriptions.length) {
          if (gDebug) console.log('>>>>>> Updating Transaction Storage for ' + key + '...');
          loadTransactions(key)
            .then(function (transactions) {
              var storage = getTransactionStorage(key);
              updateTransactionsStorage(storage, transactions);
              updateSubscriptions(storage);
            })
            .catch(printError);
        }
      });
    }, 60000);

    /**
     *
     * @param {*} date
     */
    function TransactionStorage(date) {
      this.date = date;
      this.items = [];
      this.promise = null;
      this.subscriptions = [];
      this.updated = null;
    }

    TransactionStorage.prototype.subscribe = function (cb) {
      var subscription = { id: TransactionStorage.count++, callback: cb };
      this.subscriptions.push(subscription);
      return subscription;
    };

    TransactionStorage.prototype.unsubscribe = function (subscription) {
      var n = this.subscriptions.length;
      this.subscriptions = this.subscriptions.filter(function (value) {
        return value !== subscription;
      });

      var wasRemoved = n !== this.subscriptions.length;

      if (wasRemoved) {
        if (gDebug) console.log('>>>>>> subscription was removed');
      } else {
        if (gDebug) console.log('>>>>>> subscription was not found');
      }

      return wasRemoved;
    };

    TransactionStorage.count = 0;
    Transaction.count = 0;

    /**
     *
     * @example
     *    {
     *      'agreementDate': '2021-05-10T00:00:00',
     *      'masterTransactionNumber': 'C-S19968-O',
     *      'sellerId': 46,
     *      'sellerName': 'Chevron Products Company',
     *      'buyerId': 201,
     *      'buyerName': 'PBF Holding Company LLC',
     *      'productName': 'DOMESTIC SWEET',
     *      'product2Name': '',
     *      'location': 'CUSHING, OK',
     *      'totalVolume': 150000,
     *      'deliveryStartDate': '2021-06-01T00:00:00',
     *      'deliveryEndDate': '2021-06-30T00:00:00',
     *      'priceDifferential': 0.08,
     *      'modifiedOnUtc': '2021-05-10T17:40:40'
     *    }
     * @param {*} response
     * @class
     */
    function Transaction(response) {
      this.id = Transaction.count++;
      this.agreementDate = new Date(response.agreementDate);
      this.masterTransactionNumber = response.masterTransactionNumber;
      this.sellerId = response.sellerId;
      this.sellerName = response.sellerName;
      this.buyerId = response.buyerId;
      this.buyerName = response.buyerName;
      this.productName = response.productName;
      this.product2Name = response.product2Name;
      this.location = response.location;
      this.totalVolume = response.totalVolume;
      this.deliveryStartDate = new Date(response.deliveryStartDate);
      this.deliveryEndDate = new Date(response.deliveryEndDate);
      this.priceDifferential = response.priceDifferential;
      this.modifiedOnUtc = new Date(response.modifiedOnUtc + 'Z');

      this.productSymbol = 'ABC';

      this.agreementDateText = $filter('date')(this.agreementDate, 'yyyy/MM/dd');
      this.deliveryStartDateText = $filter('date')(this.deliveryStartDate, 'yyyy/MM');
      this.modifiedOnUtcText = $filter('date')(this.modifiedOnUtc, 'yyyy/MM/dd HH:mm', $rootScope.tz);

      this.title =
        'Product Name: ' +
        this.productName +
        '. Pricing Location: ' +
        this.location +
        '.' +
        ' Agreement Date: ' +
        this.agreementDateText +
        '. Transaction: ' +
        this.masterTransactionNumber;
    }

    Transaction.prototype.search = function (text) {
      var query = text.toLowerCase();

      return (
        this.productSymbol.toLowerCase().includes(query) ||
        this.productName.toLowerCase().includes(query) ||
        this.deliveryStartDateText.toLowerCase().includes(query) ||
        this.modifiedOnUtcText.toLowerCase().includes(query) ||
        this.totalVolume.toString().includes(query) ||
        this.priceDifferential.toString().includes(query) ||
        this.location.toLowerCase().includes(query)
      );
    };

    /**
     *
     * @param {*} response
     * @class
     */
    function Trade(response) {
      this.grades = response.grades;
      this.tradeId = response.tradeId;
      this.tradeDate = response.tradeDate;
      this.tradeDiff = response.tradeDiff;
      this.term = response.term;
      this.totalVolume = response.totalVolume;
      this.primaryProduct = response.primary_Product;
      this.primaryLocation = response.primary_Location;
      this.primaryPipeline = response.primary_Pipeline;
      this.secondaryProduct = response.secondary_Product;
      this.secondaryLocation = response.secondary_Location;
      this.secondaryPipeline = response.secondary_Pipeline;
      this.executionTimeUtc = response.executionTimeUtc;
      this.isRevised = response.isRevised;
      this.modifiedOnUtc = response.modifiedOnUtc;
      this.status = response.status;
    }

    return {
      Grades: gLdsGrades,
      getToday: getToday,
      getTransactions: getTransactions,
      getTrades: getTrades,
      getVolumeWeightedAveragePrices: getVolumeWeightedAveragePrices,
      getDailyTable: getDailyTable,
      getGradeById: getGradeById,
      getGradeByName: getGradeByName,
      getReports: getReports,
      getSignedUrlOfReport: getSignedUrlOfReport,
      getFileContentOfReport: getFileContentOfReport,
      requestAccessForEodReport: requestAccessForEodReport,
    };

    /**
     *
     * @return {string}
     */
    function getToday() {
      return moment().tz('America/Chicago').startOf('day').format('YYYY/MM/DD');
    }

    // /**
    //  *
    //  * @param {string} date - date
    //  * @param {Function} cb - callback
    //  * @return {number} - subscription identifier
    //  */
    // function subscribe(date, cb) {
    //   var id = gSubscriptionId++;
    //   console.log('>>>>>> new subscription', id);
    //   var storage = getTransactionStorage(date);
    //   storage.subscriptions.push({id: id, callback: cb});
    //   return id;
    // }
    //
    // /**
    //  *
    //  * @param {number} id - subscription identifier
    //  * @return {boolean}
    //  */
    // function unsubscribe(id) {
    //   var n = gTransactionStorage.subscriptions.length;
    //   gTransactionStorage.subscriptions = gTransactionStorage.subscriptions.filter(function (value) {
    //     return value.id !== id;
    //   });
    //
    //   var wasRemoved = n !== gTransactionStorage.subscriptions.length;
    //   console.log('>>>>>> remove subscription', id, wasRemoved);
    //   return wasRemoved;
    // }

    /**
     *
     * @param {ecapp.ITransactionStorage} storage -
     */
    function updateSubscriptions(storage) {
      storage.subscriptions.forEach(function (subscription) {
        try {
          subscription.callback();
        } catch (e) {
          console.error(e);
        }
      });
    }

    /**
     * Returns transactions promise for specific date.
     *
     * @param {string} date - date in format 2021/05/29
     * @return {angular.IPromise<ecapp.ITransactionStorage>}
     */
    function getTransactions(date) {
      var storage = getTransactionStorage(date);

      if (!storage.promise) {
        storage.promise = prepareTransactionsPromise(date);
      }

      return storage.promise;
    }

    /**
     * Returns transactions promise for specific date.
     *
     * @param {number} grade - grade
     * @param {string} date - date in format 2021-05-29
     * @return {angular.IPromise<any[]>}
     */
    function getTrades(grade, date) {
      // Load transactions
      return lbLdsApi.getTrades({ grade, start: date, end: date }).$promise.then(function (results) {
        return results.map(function (item) {
          return new Trade(item);
        });
      });
    }

    /**
     *
     * @param {string} date - date in format 2021/05/29
     * @return {angular.IPromise<ecapp.ITransactionStorage>}
     */
    function prepareTransactionsPromise(date) {
      return loadTransactions(date).then(function (transactions) {
        var storage = getTransactionStorage(date);
        updateTransactionsStorage(storage, transactions);
        return storage;
      });
    }

    // /**
    //  * Returns last transactions promise.
    //  *
    //  * @return {angular.IPromise<ecapp.ITransaction[]>}
    //  */
    // function getLastTransactions() {
    //   if (!gTransactionStorage.promise) {
    //     gTransactionStorage.promise = loadTransactions(getToday()).then(updateTransactionsStorage).then(getStoredTransactions);
    //   }
    //
    //   return gTransactionStorage.promise;
    // }

    /**
     * This function loads transactions for specific date.
     *
     * @param {string} date - date in format 2021/05/29
     * @return {angular.IPromise<ecapp.ITransaction[]>}
     */
    function loadTransactions(date) {
      date = moment(date, 'YYYY/MM/DD').format('YYYY-MM-DDTHH:mm:ss');
      // Calculate central time date
      // var date = moment().tz('America/Chicago').startOf('day').subtract(1, 'days').format('YYYY-MM-DDTHH:mm:ss');

      // Load transactions
      return lbLdsApi.getTransactions({ start: date, end: date }).$promise.then(function (results) {
        return results.map(function (item) {
          return new Transaction(item);
        });
      });
    }

    // /**
    //  *
    //  * @return {ecapp.ITransaction[]}
    //  */
    // function getStoredTransactions() {
    //   console.log('>>>>>> Get Stored Transactions');
    //   return gTransactionStorage.items;
    // }

    /**
     *
     * @param {string} date - date in format 2021/05/29
     * @return {ecapp.ITransactionStorage}
     */
    function getTransactionStorage(date) {
      if (!gTransactionStorage[date]) {
        gTransactionStorage[date] = new TransactionStorage(date);
      }

      return gTransactionStorage[date];
    }

    /**
     *
     * @param {ecapp.ITransactionStorage} storage - transaction storage
     * @param {ecapp.ITransaction[]} transactions - new transactions
     */
    function updateTransactionsStorage(storage, transactions) {
      storage.items.splice(0, storage.items.length);
      transactions.forEach(function (item) {
        storage.items.push(item);
      });

      if (gDebug) console.log('>>>>>> Transaction Storage Updated');
    }

    /**
     *
     * @param {Error} err - error
     */
    function printError(err) {
      console.error(err);
    }

    /**
     * Returns volume weighted average prices.
     *
     * @param {string} date - date in format 2021/05/29
     * @return {angular.IPromise<any>}
     */
    function getVolumeWeightedAveragePrices(date) {
      date = moment(date, 'YYYY/MM/DD').format('YYYY-MM-DDTHH:mm:ss');

      // Load volume weighted average prices
      return lbLdsApi.getVolumeWeightedAveragePrices({ start: date, end: date }).$promise.then(function (results) {
        return results;
      });
    }

    /**
     *
     * @return {angular.IPromise<ecapp.IDailyTable>}
     */
    function getDailyTable() {
      return lbLdsApi.getDailyTable({}).$promise.then(function (result) {
        return result;
      });
    }

    /**
     *
     * @param {*} id
     * @return {*}
     */
    function getGradeById(id) {
      var grade = gLdsGrades.filter(function (value) {
        return value.id === id;
      })[0];
      return grade;
    }

    /**
     *
     * @param {*} symbol
     * @return {*}
     */
    function getGradeByName(symbol) {
      var grade = gLdsGrades.filter(function (value) {
        return value.name === symbol;
      })[0];
      return grade;
    }

    /**
     * Fetches reports
     * @param {string} date - date as 'YYYY-MM-DD'
     * @return {angular.IPromise<{reports: ecapp.IReportsMap}>}
     */
    function getReports(date) {
      return lbLdsApi.getReports({ date }).$promise.then(function (result) {
        return result;
      });
    }

    /**
     * Fetch report link
     * @param {string} date - date as 'YYYY-MM-DD'
     * @param {string} type - report type
     * @param {string} name - report name
     * @return {angular.IPromise<{url: string}>}
     */
    function getSignedUrlOfReport(date, type, name) {
      return lbLdsApi.getSignedUrlOfReport({ date, type, name }).$promise.then(function (result) {
        return result;
      });
    }

    /**
     * Fetch report content
     * @param {string} date - date as 'YYYY-MM-DD'
     * @param {string} type - report type
     * @param {string} name - report name
     * @return {angular.IPromise<{data: string}>}
     */
    function getFileContentOfReport(date, type, name) {
      return lbLdsApi.getFileContentOfReport({ date, type, name }).$promise.then(function (result) {
        return result;
      });
    }

    /**
     * Request access for EOD report
     * @param {string[]} deliveryMethods - delivery methods
     * @return {angular.IPromise<{data: object}>}
     */
    function requestAccessForEodReport(deliveryMethods) {
      return lbLdsApi.requestAccessForEodReport({ deliveryMethods }).$promise.then(function (result) {
        return result;
      });
    }
  }
})();
